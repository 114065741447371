<template>
  <div>
    <label :for="id" class="form-label mb-0 pb-0" v-if="label" style="font-size: 14px">
      <span class="" style="font-weight: 300">{{ label }}</span>
      <span class="text-danger" v-if="required">&nbsp;*</span>
    </label>
    <component
      :is="textarea ? 'textarea' : 'input'"
      :id="id"
      class="form-control mt-0"
      :class="hasErrors ? 'is-invalid' : margin"
      :type="type"
      :placeholder="placeholder"
      :value="buffer"
      v-maska="maska"
      @input="$emit('update:value', $event.target.value)"
      aria-describedby="validationFeedback"
      :disabled="disabled"
      :maxlength="maxlength"
    />
    <div v-if="hasErrors" id="validationFeedback" class="invalid-feedback" :class="margin">
      {{ error.map(err => err.$message).join(', ') }}
    </div>
  </div>
</template>

<script setup>
import { generateId } from '@/common'
import { defineProps, ref, watch } from 'vue'

const props = defineProps({
  error: Array,
  value: {
    required: true,
  },
  placeholder: String,
  maska: String,
  label: String,
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'text',
  },
  margin: {
    type: String,
    default: 'mb-3',
  },
  maxlength: {
    type: Number,
  },
  textarea: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const buffer = ref(props.value)
const hasErrors = ref(false)
const id = ref(`input-${generateId(12)}`)

watch(() => props.value, (value) => {
  buffer.value = value
})
watch(() => props.error, (value) => {
  hasErrors.value = value && value.length > 0
})
</script>

<style scoped>
</style>
