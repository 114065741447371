<template>
  <div class="collapse navbar-collapse">
    <ul class="navbar-nav">
      <li class="nav-item">
        <router-link :to="{name: 'manager-review'}" class="nav-link mx-3">ПОВЕРКИ</router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{name: 'manager-history'}" class="nav-link mx-3">РЕЕСТР</router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{name: 'manager-archive'}" class="nav-link mx-3">АРХИВ</router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{name: 'homes'}" class="nav-link mx-3">МОИ ДОМА</router-link>
      </li>
    </ul>
  </div>
</template>

<script setup>
</script>

<style scoped>

</style>
