import axios from 'axios'

class Api {
  constructor () {
    this.client = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
    })
    this.client.interceptors.request.use(config => {
      if (this.token != null) {
        config.headers.Authorization = `Bearer ${this.token}`
      }
      return config
    })

    this.token = null
  }

  async get (url, config = {}) {
    const response = await this.client.get(url, config)
    const { status, data } = response.data
    if (status !== 'ok') {
      throw new Error(status)
    }
    return data
  }

  async postForFile (url, payload = null, config = {}) {
    const response = await this.client.post(url, payload, {
      headers: {
        'Content-Disposition': 'attachment; filename=template.xlsx',
      },
      responseType: 'arraybuffer',
      ...config,
    })
    if (response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      const data = new Blob([response.data])
      return {
        status: 'ok',
        url: window.URL.createObjectURL(data),
      }
    }
    return null
  }

  async post (url, payload = null, config = {}) {
    const response = await this.client.post(url, payload, config)
    const { status, data } = response.data
    if (status !== 'ok') {
      throw new Error(status)
    }
    return data
  }
}

export default new Api()
