<template>
  <pvk-header/>
  <router-view/>
</template>

<script setup>
import PvkHeader from '@/components/Navbar'
import { onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
onMounted(() => {
  store.dispatch('initialize')
})
</script>

<style>
.fh {
  min-height: 100vh !important;
  height: 1px;
}

.btn-primary {
  background-color: #38485C;
}

.btn-primary:hover {
  background-color: #2b3747;
}

.btn-outline-primary {
  border-color: #38485C;
  color: #38485C;
}

.btn-outline-primary:hover {
  border-color: #2b3747;
  background-color: #2b3747;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: #2b3747;
}

input::placeholder {
  opacity: .45 !important;
}

.pointer {
  cursor: pointer;
}

/* Tooltip text */
.ttp .tooltiptext {
  display: flex;
  align-items: center;
  align-content: center;
  visibility: hidden;
  background-color: black;
  height: 32px !important;
  color: #fff;
  text-align: center;
  padding: 6px 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.ttp:hover .tooltiptext {
  visibility: visible;
}

.mt-8p {
  margin-top: 8px !important;
}
</style>
