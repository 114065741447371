import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: () => {
      const loggedIn = localStorage.getItem('loggedIn') === 'true'
      return { name: loggedIn ? 'profile' : 'landing' }
    },
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/Welcome.vue'),
    meta: { public: true },
  },
  {
    path: '/landing',
    name: 'landing',
    component: () => import('../views/Landing.vue'),
    meta: { public: true },
  },
  {
    path: '/landing-company',
    name: 'landing-company',
    component: () => import('../views/LandingCompany.vue'),
    meta: { public: true },
  },
  {
    path: '/user-ticket',
    name: 'user-ticket',
    component: () => import('../views/UserTicket.vue'),
    meta: { public: true },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/ResetPassword.vue'),
    meta: { public: true },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile.vue'),
  },
  {
    path: '/employees',
    name: 'employees',
    component: () => import('../views/lab/Employees.vue'),
    meta: { role: 'lab' },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/lab/Dashboard.vue'),
    meta: { role: 'lab' },
  },
  {
    path: '/homes',
    name: 'homes',
    component: () => import('../views/manager/Homes.vue'),
    meta: { role: 'manager' },
  },
  {
    path: '/ticket/:id',
    name: 'ticket',
    component: () => import('../views/ticket/Ticket.vue'),
  },
  {
    path: '/manager-review',
    name: 'manager-review',
    component: () => import('../views/manager/Review.vue'),
    meta: { role: 'manager' },
  },
  {
    path: '/manager-history',
    name: 'manager-history',
    component: () => import('../views/manager/Reports.vue'),
    meta: { role: 'manager' },
  },
  {
    path: '/manager-archive',
    name: 'manager-archive',
    component: () => import('../views/manager/Archive.vue'),
    meta: { role: 'manager' },
  },
  {
    path: '/done',
    name: 'done',
    component: () => import('../views/lab/Done.vue'),
    meta: { role: 'lab' },
  },
  {
    path: '/deleted',
    name: 'deleted',
    component: () => import('../views/lab/Deleted.vue'),
    meta: { role: 'lab' },
  },
  {
    path: '/admin/tickets',
    name: 'admin-ticket',
    component: () => import('../views/AdminTicket.vue'),
    meta: { public: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const role = localStorage.getItem('role')
  if (to.matched.some(route => route.meta.role && route.meta.role !== role)) {
    next({ name: 'profile' })
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('loggedIn') === 'true'
  if (to.matched.every(route => route.meta.public) || loggedIn) {
    next()
  } else {
    next({ name: 'auth' })
  }
})

export default router
