import { helpers, required } from '@vuelidate/validators'

export const MEDIA_URL = process.env.VUE_APP_MEDIA_URL

export const generateId = (length) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength))
  }
  return result
}

export const requiredRu = helpers.withMessage('Это поле не может быть пустым', required)

export const formatDate = (dateString) => {
  const date = new Date(dateString)
  return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`
}

export const verbosifyError = (error) => {
  return {
    already_registered: 'Пользователь с таким номером телефона или email уже зарегистрирован',
    no_user: 'Неверный логин',
    password_invalid: 'Неверный пароль',
    code_invalid: 'Неверный код',
    home_already_exists: 'Дом уже находится в управлении',
    no_manager_found_with_inn: 'Не найдено управляющих компаний с предоставленным ИНН',
    already_registered_inn: 'Пользователь с таким номером телефона, email или ИНН уже зарегистрирован',
  }[error] || error
}

export const requiredNotFilled = (rules, state) => {
  let filled = 0
  for (const field in state.data) {
    if (rules[field] && rules[field].requiredRu) {
      filled += state.data[field] ? 0 : 1
    }
  }
  return filled
}

export const serializeDate = (dateLike) => {
  const d = new Date(dateLike)
  if (d.toString() === 'Invalid Date') {
    throw Error('Invalid date')
  }
  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${d.getDate()}`
}

export const trim = (s, len) => {
  if (s.length < len) return s
  return s.substring(0, len - 3) + '...'
}

export const formatName = (user) => `${user.lastName} ${user.firstName.charAt(0).toUpperCase()}.` + (user.middleName ? ` ${user.middleName.charAt(0).toUpperCase()}.` : '')

export const formatAddress = (ticket) => `г. ${ticket.home.city}, ${ticket.home.street} ${ticket.home.streetNumber}, кв. ${ticket.apt}`

export const prepareNumber = (rawValue) => rawValue === null || rawValue.length === 0 ? null : Number(rawValue)
