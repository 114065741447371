export default {
  AUTH: {
    SIGNUP: '/auth/signup-inn/',
    SIGNIN: '/auth/signin/',
    PROFILE: '/auth/profile/',
    REQUEST_PASSWORD_RESET: '/auth/reset/request/',
    VERIFY_PASSWORD_RESET_CODE: '/auth/reset/verify-code/',
    RESET_PASSWORD: '/auth/reset/complete/',
  },
  COMPANY: {
    GET: '/company/my/',
    SAVE: '/company/save/',
    ISSUE_CODE: '/company/join/issue/',
    EMPLOYEES: '/company/employees/',
    REMOVE_EMPLOYEE: '/company/employees/remove/',
    ADMIN_LABS: '/company/admin/labs/',
  },
  HOME: {
    CITIES: '/homes/cities/',
    STREETS: '/homes/streets/',
    NEW: '/homes/new/',
    MY: '/homes/my/',
    REMOVE: '/homes/remove/',
    AVAILABLE: '/homes/available/',
  },
  TICKETS: {
    NEW: '/tickets/update/',
    NEW_ADMIN: '/tickets/admin/new/',
    LIST: '/tickets/list/',
    GET: '/tickets/get/',
    METER_INFO: '/tickets/meter-info/',
    SEND_TO_MANAGER_REVIEW: '/tickets/send-to-manager-review/',
    TOGGLE_WAIT: '/tickets/wait/toggle/',
    DELETE: '/tickets/delete/',
    RESTORE: '/tickets/restore/',
    CLOSE: '/tickets/close/',
    REPORT: '/tickets/generate-report/',
    NEW_USER: '/tickets/user/new/',
    NEW_REPORT: '/tickets/lab/report/',
    EDIT_REPORT: '/tickets/lab/report/edit/',
    BACK_TO_LAB_REVIEW: '/tickets/back-to-review/',
    REJECT_REVIEW_MANAGER: '/tickets/manager/reject/',
  },
}
