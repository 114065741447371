<template>
  <div>
    <label :for="id" class="form-label mb-0 pb-0" v-if="label" style="font-size: 14px">
      <span class="" style="font-weight: 300">{{ label }}</span>
      <span class="text-danger" v-if="required">&nbsp;*</span>
    </label>
    <slot :id="id" :unitId="id"/>
    <div v-if="error && error.length" class="text-danger">
      {{ error.map(err => err.$message).join(', ') }}
    </div>
  </div>
</template>

<script setup>
import { generateId } from '@/common'
import { defineProps, ref } from 'vue'

defineProps({
  error: Array,
  label: String,
  required: {
    type: Boolean,
    default: false,
  },
})

const id = ref(`input-${generateId(12)}`)
</script>

<style scoped>
</style>
