<template>
  <div class="d-flex flex-column justify-content-around photo-container">
    <div class="position-relative">
      <div class="rotation-wrapper">
        <img class="photo w-100" :src="src" :alt="alt" :ref="el => { state.element = el }"
             @click="state.locked = !state.locked" @load="state.setupDone = true">
      </div>

      <div class="position-absolute" style="top: 10px; right: 10px" v-if="state.setupDone">
        <p-icon name="zoom_in" @click="state.factor += 1" class="text-white rounded-circle bg-dark p-1 ms-2" clickable
                v-if="false"/>
        <p-icon name="zoom_out" @click="state.factor = Math.max(1, state.factor - 1)"
                class="text-white rounded-circle bg-dark p-1 ms-2" clickable v-if="false"/>
        <p-icon name="print" @click="printImage" class="text-white rounded-circle bg-dark p-1 ms-2" clickable/>
        <p-icon name="autorenew" @click="state.rotation += 90" class="text-white rounded-circle bg-dark p-1 ms-2"
                clickable/>
        <p-icon name="sync" @click="state.rotation -= 90" class="text-white rounded-circle bg-dark p-1 ms-2" clickable/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, reactive, watch } from 'vue'

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    required: false,
  },
  panScaleX: {
    type: Number,
    required: false,
    default: 1.4,
  },
  panScaleY: {
    type: Number,
    required: false,
    default: 1.4,
  },
})

const state = reactive({
  element: null,
  factor: 2,
  scaled: false,
  transformOrigin: 'center',
  rotation: 0,
  locked: false,
  setupDone: false,
})

const printImage = () => {
  const markup = `
  <html>
  <body>
    <img src="${props.src}" onLoad="window.print(); window.close()"/>
  </body>
  </html>
  `
  const popup = window.open('about:blank', `Печать ${props.alt}`, 'height=800,width=800')
  popup.document.write(markup)
  popup.focus()
}

const applyStyles = () => {
  state.element.parentElement.style.transform = `rotate(${state.rotation}deg)`
  if (state.locked) {
    return
  }

  state.element.style = `
    transform: scale(${state.scaled ? state.factor : 1});
    transform-origin: ${state.transformOrigin};
  `
}

watch(() => props.src, () => {
  state.factor = 2
  state.scaled = false
  state.transformOrigin = 'center'
  state.rotation = 0
  state.locked = false
})
watch(state, applyStyles)

onMounted(() => {
  state.element.parentElement.parentElement.style.maxHeight = `${state.element.parentElement.parentElement.parentElement.offsetHeight}px`
  state.element.parentElement.parentElement.style.maxWidth = `${state.element.parentElement.parentElement.parentElement.offsetWidth}px`

  applyStyles()

  state.element.addEventListener('mouseover', () => {
    state.scaled = true
  })
  state.element.addEventListener('mouseout', () => {
    state.scaled = false
  })
  state.element.addEventListener('mousemove', (evt) => {
    const rect = state.element.getBoundingClientRect()
    const width = rect.right - rect.left
    const height = rect.bottom - rect.top
    const originX = (50 - ((evt.pageX - rect.left) / width) * 100) * -props.panScaleX + 50
    const originY = (50 - ((evt.pageY - rect.top) / height) * 100) * -props.panScaleY + 50
    state.transformOrigin = `${Math.min(100, Math.max(0, originX))}% ${Math.min(100, Math.max(0, originY))}%`
  })
})
</script>

<style>
.photo {
  overflow: hidden;
  transition: transform .2s ease-out;
  object-fit: contain;
}

.photo-container {
  overflow: hidden;
}

.txt {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 10%;
  left: 0;
  font-family: 'Roboto Slab', serif;
  font-size: 9px;
  line-height: 12px;
  text-align: center;
  cursor: default;
}

.modal-dialog-photo {
  max-width: 50vw !important;
}
</style>
