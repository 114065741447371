<template>
  <p-base-form-unit
    :error="error"
    :label="label"
    :required="required"
    :disabled="disabled"
  >
    <template v-slot="{ unitId }">
    <vue-multiselect
      ref="element"
      v-model="state.buffer"
      :id="unitId"
      :options="state.options"
      :searchable="true"
      :close-on-select="true"
      :allow-empty="false"
      :show-labels="false"
      :show-no-results="false"
      @search-change="loadOptions"
      @select="selected"
      :label="optionLabel"
      :placeholder="placeholder"
      :loading="state.isLoading"
      :disabled="disabled"
      :optionsLimit="100"
    >
      <template v-slot:option="props">
        <div class="d-flex justify-content-between align-content-center">
          <span class="my-auto">
          {{ props.option[optionLabel] }}
          </span>
          <p-icon v-if="props.option.virtual" name="add"/>
        </div>
      </template>
      <template v-slot:noOptions>
        {{ searchPlaceholder }}
      </template>
    </vue-multiselect>
</template>

  </p-base-form-unit>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, reactive, ref, watch } from 'vue'
import { debounce } from 'vue-debounce'
import PBaseFormUnit from './BaseFormUnit'

const props = defineProps({
  error: null,
  optionLabel: null,
  placeholder: null,
  queryTerm: {
    type: Function,
    required: false,
    default: null,
  },
  value: null,
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  label: {
    type: String,
    required: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  searchPlaceholder: {
    type: String,
    required: false,
    default: 'Начните вводить название',
  },
  noResultsCreateItem: {
    type: Boolean,
    required: false,
    default: true,
  },
  options: {
    type: Array,
    required: false,
    default: null,
  },
})

const emit = defineEmits(['update:value'])

const selected = (option) => {
  state.buffer = option
  emit('update:value', option)
}

const state = reactive({
  options: props.options,
  isLoading: false,
  buffer: props.value,
})

const element = ref(null)

const handleError = () => {
  const id = element.value.id

  const inputElement = document.querySelector(`#${id}`)
  if (!inputElement) {
    return
  }
  const parent = inputElement.parentElement
  parent.classList.add('form-control')

  if (props.error && props.error.length) {
    parent.classList.add('is-invalid')
  } else {
    parent.classList.remove('is-invalid')
  }
}

const loadOptions = debounce(async (value) => {
  if (props.disabled || props.queryTerm === null) {
    return
  }

  if (!value && (props.value && props.value.virtual)) {
    return
  }
  state.isLoading = true

  state.options = await props.queryTerm(value)
  if (props.noResultsCreateItem && value && state.options.length === 0) {
    state.options = [{
      name: value,
      virtual: true,
    }]
  }

  state.isLoading = false
}, '300ms')

watch(() => props.value, () => {
  state.buffer = props.value
})
watch(() => props.error, handleError)
watch(() => props.disabled, loadOptions)
watch(() => props.options, () => {
  state.options = props.options
})
onMounted(() => loadOptions() && handleError())
</script>

<style>
.multiselect {
  height: 38px !important;
  min-width: 248px;
}

.multiselect__tags {
  height: 38px !important;
  min-height: auto !important;
}

.multiselect__option--highlight {
  background: white;
  outline: none;
  color: black;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #212529;
  color: #fff
}
</style>
