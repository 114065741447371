<template>
  <vue-final-modal v-model="state.showProxy"
                   classes="modal-container h-100 d-flex justify-content-center align-items-center"
                   :content-class="'modal-dialog modal-content px-3 py-4 ' + modalClass">
    <div class="modal__close d-flex justify-content-between">
      <h5 class="ps-3">
        <slot name="title"/>
      </h5>
      <i class="material-icons me-1 pointer" @click="$emit('close')">close</i>
    </div>
    <hr>
    <div class="px-3">
      <slot name="content"/>
    </div>
  </vue-final-modal>
</template>

<script setup>
import { defineEmits, defineProps, reactive, watch } from 'vue'
import { VueFinalModal } from 'vue-final-modal'

const props = defineProps({
  show: {
    type: Boolean,
    required: false,
    default: true,
  },
  modalClass: {
    type: String,
    required: false,
    default: '',
  },
})

const state = reactive({
  showProxy: props.show,
})

const emit = defineEmits(['close'])

watch(() => state.showProxy, (value) => !value ? emit('close') : null)
watch(() => props.show, (value) => {
  state.showProxy = value
})
</script>

<style>
.modal-dialog {
  max-height: 90vh;
  overflow: auto;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
  }
}
</style>
