import api from '@/api'
import urls from '@/api/urls'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state () {
    return {
      username: null,
      resetPasswordCode: null,
      resetPasswordState: null,
      newTicket: null,
      data: null,

      loggedIn: false,
      token: null,
      user: null,
      company: null,

      adminKey: null,
    }
  },
  mutations: {
    setUsername (state, value) {
      state.username = value
    },
    resetPasswordRequested (state) {
      state.resetPasswordState = 'code'
    },
    codeValid (state, code) {
      state.resetPasswordState = 'change_password'
      state.resetPasswordCode = code
    },
    passwordReset (state) {
      state.resetPasswordState = null
      state.resetPasswordCode = null
    },
    login (state, { user, token }) {
      state.user = user
      state.token = token
      state.loggedIn = true
      api.token = token
      localStorage.setItem('loggedIn', 'true')
    },
    logout (state) {
      state.user = null
      state.token = null
      state.loggedIn = false
      state.company = null
      localStorage.removeItem('loggedIn')
    },
    setCompany (state, company) {
      state.company = company

      if (company) {
        localStorage.setItem('role', company.kind)
      } else {
        localStorage.removeItem('role')
      }
    },
    setNewTicket (state, ticket) {
      state.newTicket = ticket
    },
    setAdminKey (state, value) {
      state.adminKey = value
      api.token = value
    },
  },
  actions: {
    async initialize (store) {
      store.state.company = null
      store.state.user = null
      try {
        api.token = store.state.token

        const user = await api.get(urls.AUTH.PROFILE)
        store.commit('login', {
          token: store.state.token,
          user,
        })
        await store.dispatch('getCompany')
      } catch (err) {
        store.commit('logout')
      }
    },
    async login (store, payload) {
      await store.commit('login', payload)
      await store.dispatch('getCompany')
    },
    async getCompany (store) {
      try {
        const company = await api.get(urls.COMPANY.GET)
        store.commit('setCompany', company)
      } catch (err) {
        store.commit('setCompany', null)
      }
    },
  },
  modules: {
  },
  plugins: [
    createPersistedState(),
  ],
})
