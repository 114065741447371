<template>
  <nav class="navbar border-bottom navbar-light bg-white navbar-expand">
    <div class="container-fluid">
      <router-link :to="{name: 'landing'}" class="navbar-brand ms-5 logo">pv-1.ru</router-link>

      <pvk-lab v-if="store.state.user && store.state.user.kind === 'lab'"/>
      <pvk-manager v-if="store.state.user && store.state.user.kind === 'manager'"/>

      <div class="d-flex align-content-center align-items-center" v-if="!store.state.loggedIn">
        <p-icon name="phone" class="me-2"/>
        <a href="tel:+79035502040">
          +7 (903) 550-20-40
        </a>
      </div>
      <div class="d-flex align-content-center align-items-center" v-if="!store.state.loggedIn">
        <p-icon name="mail" class="me-2"/>
        <a href="mailto:info@pv-1.ru">
          info@pv-1.ru
        </a>
      </div>

      <router-link :to="{name: 'profile'}" class="me-3" v-if="store.state.company">
        {{ store.state.company.name }}
      </router-link>

      <router-link :to="{name: 'auth'}" class="me-3" v-else>
        <div class="d-flex align-items-center">
          <p-icon name="login" class="me-2"/>
          Вход
        </div>
      </router-link>

      <p-icon v-if="store.state.loggedIn" name="logout" class="me-4" @click="showLogoutModal=true" clickable/>
    </div>
    <p-confirmation-modal
      v-if="showLogoutModal"
      @accept="logout"
      @cancel="showLogoutModal = false"
    >
      <template v-slot:title>
        Выход
      </template>
      <template v-slot:confirmation-text>
        Выйти из аккаунта?
      </template>
    </p-confirmation-modal>
  </nav>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import PvkLab from './Lab'
import PvkManager from './Manager'
import PConfirmationModal from '@/components/modals/ConfirmationModal'

const showLogoutModal = ref(false)

const store = useStore()
const router = useRouter()

const logout = () => {
  showLogoutModal.value = false
  store.commit('logout')
  router.push({ name: 'auth' })
}
</script>

<style>
.logo {
  font-weight: 800;
  font-size: 1.32em;
}

.router-link-active {
  color: black !important;
}
</style>
