<template>
  <p-base-form-unit
    :error="error"
    :label="label"
    :required="required"
    :disabled="disabled"
  >
    <template v-slot="{ unitId }">
      <vue-datepicker
        :id="unitId"
        :inputClassName="error && error.length ? 'form-control is-invalid' : 'form-control'"
        v-model="buffer"
        locale="ru"
        :autoApply="true"
        :textInput="true"
        format="dd.MM.yyyy"
        :enableTimePicker="false"
        :disabled="disabled"
        :startTime="{ hours: 0, minutes: 0, seconds: 0,}"
        :range="range"
      />
    </template>

  </p-base-form-unit>
</template>

<script setup>
import PBaseFormUnit from './BaseFormUnit'
import { defineEmits, defineProps, ref, watch } from 'vue'

const props = defineProps({
  error: Array,
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  range: {
    type: Boolean,
    default: false,
  },
  value: null,
})

const emit = defineEmits(['update:value'])

const buffer = ref(props.value ? new Date(props.value) : null)
watch(() => buffer.value, () => emit('update:value', buffer.value))
watch(() => props.value, () => {
  buffer.value = props.value
})
</script>

<style scoped>

</style>
