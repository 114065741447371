<template>
<div data-bs-toggle="tooltip" data-bs-placement="right" :title="text">
  <slot/>
</div>
</template>

<script setup>
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { defineProps, onMounted, onUnmounted } from 'vue'

defineProps({
  text: {
    type: String,
    required: true,
  },
})

onMounted(() => {
  Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    .forEach(tooltipNode => new Tooltip(tooltipNode))
})
onUnmounted(() => {
  Array.from(document.querySelectorAll('div.tooltip')).forEach(el => el.remove())
})
</script>

<style scoped>

</style>
