import PDatetimePicket from '@/components/ui/DatetimePicker'
import PIcon from '@/components/ui/Icon'
import PIconButton from '@/components/ui/IconButton'
import PInput from '@/components/ui/Input'
import PMultiselect from '@/components/ui/Multiselect'
import PPhoto from '@/components/ui/Photo'
import PSelect from '@/components/ui/Select'
import PTooltip from '@/components/ui/Tooltip'
import Slideout from '@hyjiacan/vue-slideout'
import '@hyjiacan/vue-slideout/dist/slideout.css'
import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import Maska from 'maska'

import { createApp } from 'vue'
import vueDebounce from 'vue-debounce'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

const Vue = createApp(App)
Vue.component('p-input', PInput)
Vue.component('p-select', PSelect)
Vue.component('p-icon-button', PIconButton)
Vue.component('p-icon', PIcon)
Vue.component('p-multiselect', PMultiselect)
Vue.component('p-datetime-picker', PDatetimePicket)
Vue.component('p-photo', PPhoto)
Vue.component('p-tooltip', PTooltip)

Vue.component('vue-multiselect', VueMultiselect)
Vue.component('vue-datepicker', Datepicker)
Vue.component('vue-cropper', VueCropper)

Vue.use(vueDebounce)

const sentryDns = process.env.VUE_APP_SENTRY_DNS
if (sentryDns) {
  Sentry.init({
    Vue,
    dsn: sentryDns,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['dev.pv-1.ru', 'pv-1.ru', /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  })
}
Vue.use(Slideout, {})
Vue
  .use(store)
  .use(router)
  .use(Maska)
  .mount('#app')
