<template>
  <div class="collapse navbar-collapse ms-5">
    <ul class="navbar-nav">
      <li class="nav-item">
        <router-link :to="{name: 'dashboard'}" class="nav-link mx-3">ЗАЯВКИ</router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{name: 'done'}" class="nav-link mx-3">ВЫПОЛНЕНО</router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{name: 'deleted'}" class="nav-link mx-3">УДАЛЕННЫЕ</router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{name: 'employees'}" class="nav-link mx-3">СОТРУДНИКИ</router-link>
      </li>
    </ul>
  </div>
</template>

<script setup>
</script>

<style scoped>

</style>
